import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Modal from '../../../modal';
import TitleCard from '../../../title/title-card';
import TableMaterial from '../../../table';

import { AddButton, StyledPaper, useStyles } from '../../../styles';

import { city, neighborhood } from '../../schema';
import DataCity from './data-city';
import DataNeighborhood from './data-neighborhood';
// import DataZip from './data-zip';

import City from './city';
import Neighborhood from './neighborhood';
import Zip from './zip';

import { useSettings } from '../../../../contexts/settings';
import { useAuth } from '../../../../contexts/auth';

export default function Region() {
  const styles = useStyles();
  const formRef = useRef();
  const { id } = useParams();

  const { verifyPerms } = useAuth();
  const { addLocation, locations, fetchLocations } = useSettings();

  const renderCity = (data) => <DataCity data={data} />;
  const renderNeighborhood = (data) => <DataNeighborhood data={data} />;
  // const renderZip = (data) => <DataZip data={data} />;

  const [initialData, setInitialData] = useState(null);

  const [modalCity, setModalCity] = useState(false);
  const [modalNeighborhood, setModalNeighborhood] = useState(false);
  const [modalZip, setModalZip] = useState(false);

  const [zipWeekDays, setZipWeekDays] = useState(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
  const [districtWeekDays, setDistrictWeekDays] = useState(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
  const [cityWeekDays, setCityWeekDays] = useState(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);

  useEffect(() => {
    setInitialData({ city: { service_type: 'ground' }, zip: { service_type: 'ground' }, district: { service_type: 'ground' } });
  }, []);

  useEffect(() => {
    (async () => {
      await fetchLocations(id);
    })();
  }, [fetchLocations, id]);

  const handleZipLocation = useCallback(async () => {
    try {
      formRef.current.setErrors({});
      const data = formRef.current.getData();

      const schema = Yup.object().shape({
        zip_to: Yup.string().required('CEP final obrigatório').min(8, 'CEP final deve ter 8 dígitos').max(8, 'CEP deve ter 8 dígitos'),
        zip_from: Yup.string().required('CEP inicial obrigatório').min(8, 'CEP inicial deve ter 8 dígitos').max(8, 'CEP deve ter 8 dígitos'),
      });

      await schema.validate(data.zip, {
        abortEarly: false,
      });
      const shipping_time = Number(data.zip.shipping_time);
      await addLocation({ ...data.zip, shipping_time, week_availibility: zipWeekDays, branch_id: id });

      setModalZip(!modalZip);

      return toast.success('Um novo CEP foi adicionado');
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        toast.error(err.errors[0]);
        return formRef.current.setErrors({
          zip: validationErrors,
        });
      }
      return toast.error('Ocorreu um erro. Tente novamente');
    }
  }, [addLocation, zipWeekDays, modalZip, id]);

  const handleDistrictLocation = useCallback(async () => {
    const data = formRef.current.getData();

    const shipping_time = Number(data.district.shipping_time);
    await addLocation({ ...data.district, shipping_time, week_availibility: districtWeekDays, branch_id: id });

    setModalNeighborhood(!modalNeighborhood);

    toast.success('Um novo Bairro foi adicionado');
  }, [addLocation, districtWeekDays, modalNeighborhood, id]);

  const handleCityLocation = useCallback(async () => {
    const data = formRef.current.getData();

    const shipping_time = Number(data.city.shipping_time);
    await addLocation({ ...data.city, /* ships_low_temperature: data.city.ships_low_temperature === 'yes' ? true : false, */ shipping_time, week_availibility: cityWeekDays, branch_id: id });

    setModalCity(!modalCity);

    toast.success('Uma nova Cidade foi adicionada');
  }, [addLocation, cityWeekDays, modalCity, id]);

  return (
    <>

      <TitleCard>Cadastro de Serviços de Entrega</TitleCard>

      <StyledPaper>
        {/* <h5 style={{ fontWeight: 'bold', marginBottom: 24 }}>Lista de CEPs</h5>

        {locations?.items && (
          <TableMaterial
            noPagination
            headers={zip}
            renderItem={renderZip}
            listQuery={locations.items.filter((item) => item.zip_from && item.zip_to)}
          />
        )}

        <Grid container className={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) && 'disabled-events'}>
          <Grid item onClick={() => setModalZip(!modalZip)} style={{ cursor: 'pointer' }}>
            <IconButton type="button" className={styles.iconButton}>
              <AddCircleOutline fontSize="small" color={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) ? 'disabled' : 'primary'} />
            </IconButton>

            <AddButton type="button">Adicionar Novo CEP</AddButton>
          </Grid>
        </Grid> */}

        <h5 style={{ fontWeight: 'bold', marginBottom: 24 }}>Lista de Bairros</h5>

        {locations?.items && (
          <TableMaterial
            noPagination
            headers={neighborhood}
            renderItem={renderNeighborhood}
            listQuery={locations.items.filter((item) => item.district)}

          />

        )}

        <Grid container className={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) && 'disabled-events'}>
          <Grid item onClick={() => setModalNeighborhood(!modalNeighborhood)} style={{ cursor: 'pointer' }}>
            <IconButton type="button" className={styles.iconButton}>
              <AddCircleOutline fontSize="small" color={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) ? 'disabled' : 'primary'} />
            </IconButton>

            <AddButton type="button">Adicionar Novo Bairro</AddButton>
          </Grid>
        </Grid>

        <h5 style={{ fontWeight: 'bold', margin: '16px 0 24px' }}>Lista de Cidades e Estados</h5>

        {locations?.items && (
          <TableMaterial
            noPagination
            headers={city}
            renderItem={renderCity}
            listQuery={locations.items.filter((item) => (item.city && !item.district) || (item.state && !item.district))}
          />
        )}

        <Grid container className={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) && 'disabled-events'}>
          <Grid item onClick={() => setModalCity(!modalCity)} style={{ cursor: 'pointer' }}>
            <IconButton type="button" className={styles.iconButton}>
              <AddCircleOutline fontSize="small" color={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) ? 'disabled' : 'primary'} />
            </IconButton>

            <AddButton type="button">Adicionar Novo Estado e Cidade</AddButton>
          </Grid>
        </Grid>
      </StyledPaper>

      <Form ref={formRef} initialData={initialData}>

        <Modal
          isVisible={modalZip}
          title="Adicionar Novo CEP"
          toggle={() => setModalZip(!modalZip)}
          buttonPrimary="adicionar"
          buttonSecondary="cancelar"
          onClick={handleZipLocation}
        >
          <Zip weekDays={zipWeekDays} setWeekDays={setZipWeekDays} />
        </Modal>

        <Modal
          isVisible={modalNeighborhood}
          title="Adicionar Novo Bairro"
          toggle={() => setModalNeighborhood(!modalNeighborhood)}
          buttonPrimary="adicionar"
          buttonSecondary="cancelar"
          onClick={handleDistrictLocation}
        >
          <Neighborhood weekDays={districtWeekDays} setWeekDays={setDistrictWeekDays} />
        </Modal>

        <Modal
          isVisible={modalCity}
          title="Adicionar Novo Estado e Cidade"
          toggle={() => setModalCity(!modalCity)}
          buttonPrimary="adicionar"
          buttonSecondary="cancelar"
          onClick={handleCityLocation}
        >
          <City weekDays={cityWeekDays} setWeekDays={setCityWeekDays} />
        </Modal>
      </Form>

    </>
  );
}
